var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "section-content" },
        [
          _c(
            "b-card-group",
            { attrs: { deck: "" } },
            [
              _c(
                "b-card",
                {
                  staticClass:
                    "card-layout shadow-sm p-3 mb-5 bg-white rounded",
                  staticStyle: {
                    "max-width": "30rem",
                    "min-height": "25rem",
                    "margin-left": "35px",
                  },
                },
                [
                  _c("b-card-title", [_vm._v("OLÁ!")]),
                  _c(
                    "b-card-text",
                    { staticClass: "card-subtitle card-text_align" },
                    [_vm._v("Produtivo, prático e seguro.")]
                  ),
                  _c(
                    "b-card-text",
                    { staticClass: "card-text card-text_align" },
                    [
                      _vm._v(
                        " O Retextoar é perfeito para otimizar os trabalhos da sua equipe e empresa, uma solução inteligente para transcrição de áudios em texto, totalmente brasileiro e com uma das maiores acuracidades do mercado. "
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "button-home",
                      staticStyle: { "margin-top": "80px" },
                      attrs: { href: "#", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.showTutorial()
                        },
                      },
                    },
                    [_vm._v("Assista ao tutorial")]
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                {
                  staticClass:
                    "card-layout1 shadow-sm p-3 mb-5 bg-white rounded",
                  staticStyle: { "max-width": "60rem" },
                },
                [
                  _c("b-card-title", { staticClass: "card-title-right" }, [
                    _vm._v("Você sabia? "),
                  ]),
                  _c("b-card-text", { staticClass: "card-text" }, [
                    _c("ul", [
                      _c("li", { staticClass: "card-text1" }, [
                        _vm._v(
                          " A transcrição de áudios oferece inegável agilidade para empresas e profissionais já que o tempo de uma transcrição manual pode ser 5 vezes maior do que o tempo da duração da própria gravação. "
                        ),
                      ]),
                      _c("li", { staticClass: "card-text1" }, [
                        _vm._v(
                          " Ao utilizar um arquivo de áudio multicanal, o Retextoar consegue identificar os locutores, numerando cada um deles individualmente nas falas transcritas. Para utilizar esta facilidade, selecione a opção “áudio multicanal” na tela de upload do arquivo. "
                        ),
                      ]),
                      _c("li", { staticClass: "card-text1" }, [
                        _vm._v(
                          " O Retextoar trabalha por meio de sistemas baseados em inteligência artificial, dessa forma, sua evolução é constante e faz com que entregue uma das melhores taxas de acuracidade do mercado. "
                        ),
                      ]),
                      _c("li", { staticClass: "card-text1" }, [
                        _vm._v(
                          " Você pode solicitar a transcrição de diversos tipos de arquivos de áudio e vídeo: WAV, MP3, M4A, WMA, FLAC, OGG, MP4 E AVI. "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            id: "modal-prevent-closing",
            "ok-title": "Ok",
            title: _vm.modalTitle,
            "modal-class": "edit-title",
            size: "lg",
            "hide-footer": "",
          },
          on: { hidden: _vm.resetModal, ok: _vm.handleModalOk },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("video", {
                  attrs: { width: "100%", src: _vm.srcVideo, controls: "true" },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "section-content" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "background-section-title" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v("Boas-vindas")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }